import { LocalDictionaryWord } from '../data-access/dictionary/dictionary.model';

export const BASE_FONT_SIZE = 10;
export const BASE_FONT_VARIABLE = '--base-font';

export const TOKEN_COOKIE_KEY = 'pi20-token';
export const TOKEN_TEMP_COOKIE_KEY = 'pi20-temp-token';
export const REMEMBER_DEVICE_TOKEN_COOKIE = 'pi20-remember-device-token';

export const DEFAULT_PAGINATION_SIZES = [50, 100, 200, 250];

export const MONTH_NAMES: Record<number, string> = {
  0: 'Styczeń',
  1: 'Luty',
  2: 'Marzec',
  3: 'Kwiecień',
  4: 'Maj',
  5: 'Czerwiec',
  6: 'Lipiec',
  7: 'Sierpień',
  8: 'Wrzesień',
  9: 'Październik',
  10: 'Listopad',
  11: 'Grudzień',
};

export const WEEK_DAYS = ['pon', 'wto', 'śro', 'czw', 'pią', 'sob', 'nie'];
export const WEEK_DAY_NAMES = [
  'Niedziela',
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota',
];

export enum CalendarDialogInterval {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export const CALENDAR_DIALOG_INTERVAL_OPTIONS: LocalDictionaryWord[] = [
  {
    uid: CalendarDialogInterval.MONTH,
    publicName: 'Miesiąc',
    internalName: 'month',
  },
  {
    uid: CalendarDialogInterval.WEEK,
    publicName: 'Tydzień',
    internalName: 'week',
  },
  { uid: CalendarDialogInterval.DAY, publicName: 'Dzień', internalName: 'day' },
];
