import { Component, inject } from '@angular/core';
import { InputComponent } from '../../../../../shared/ui/controls/input/input.component';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { AnimatedButtonComponent } from '../../../../../shared/ui/controls/animated-button/animated-button.component';
import { ButtonComponent } from '../../../../../shared/ui/controls/button/button.component';
import { MatDialogRef } from '@angular/material/dialog';
import { BusinessEntityService } from '../../data-access/business-entity.service';
import {
  catchError,
  filter,
  map,
  of,
  Subject,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import { AuthUserService } from '../../../../../auth/data-access/auth-user.service';
import { toSignal } from '@angular/core/rxjs-interop';
import {matchPasswordsValidator, passwordValidator, phoneNumberValidator} from '../../../../../shared/utils/validators';

const TOKEN_VALIDITY = 5 * 60;

@Component({
  selector: 'pi-sms-confirm-dialog',
  imports: [
    InputComponent,
    FormsModule,
    AnimatedButtonComponent,
    ButtonComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './change-password-dialog.component.html',
  styleUrl: './change-password-dialog.component.scss',
})
export class ChangePasswordDialogComponent {
  private dialogRef = inject(MatDialogRef<ChangePasswordDialogComponent>);
  private fb = inject(FormBuilder);

  protected passwordForm = this.fb.group(
    {
      currentPassword: ['', [Validators.required]],
      password: ['', [passwordValidator(), Validators.required]],
      confirmPassword: ['', [Validators.required]],
    },
    { validators: matchPasswordsValidator() },
  );



  // #sendSmsCode = new Subject<void>();
  // sendSmsCode$ = this.#sendSmsCode.pipe(
  //   switchMap(() =>
  //     this.businessEntityService
  //       .sendSmsCode(this.authUserService.user()!.uid)
  //       .pipe(
  //         catchError(() => of(null)),
  //         filter((res) => !!res),
  //         switchMap(() =>
  //           timer(0, 1000).pipe(
  //             map(
  //               (secondsElapsed) =>
  //                 `${Math.floor((TOKEN_VALIDITY - secondsElapsed) / 60)}m ${
  //                   (TOKEN_VALIDITY - secondsElapsed) % 60
  //                 }s`,
  //             ),
  //             takeUntil(timer((TOKEN_VALIDITY + 1) * 1000)),
  //           ),
  //         ),
  //       ),
  //   ),
  // );

  // timer = toSignal(this.sendSmsCode$);
  //
  // sendSmsCode() {
  //   this.#sendSmsCode.next();
  // }
  //
  verify() {
    this.dialogRef.close(this.passwordForm.value);
    // this.businessEntityService
    //   .verifySmsCode(this.authUserService.user()!.uid, this.currentPassword)
    //   .subscribe(() => {
    //     this.dialogRef.close(true);
    //   });
  }

  close() {
    this.dialogRef.close(false);
  }

  constructor() {
    // this.sendSmsCode();
  }
}
