<div class="pi-dialog">
  <div class="pi-dialog__header">Zmień hasło</div>
  <div class="pi-dialog__body">
    <div class="pi-dialog__body__description">
      Aby zmienić swoje hasło, wypełnij poniższy formularz:
    </div>
    <pi-input
      type="password"
      [formControl]="passwordForm.controls.currentPassword"
      [label]="'Obecne hasło'"
    />
    <div class="pi-dialog__body__description">
      Wpisz nowe hasło. Upewnij się, że spełnia wymagania dotyczące
      bezpieczeństwa: min. 8 znaków, jedna wielka litera, jedna cyfra i jeden
      znak specjalny
    </div>
    <pi-input
      type="password"
      [formControl]="passwordForm.controls.password"
      [autocomplete]="false"
      [label]="'Nowe hasło'"
    />
    <pi-input
      type="password"
      [formControl]="passwordForm.controls.confirmPassword"
      [autocomplete]="false"
      [label]="'Powtórz hasło'"
    />
  </div>
  <div class="pi-dialog__footer">
    <pi-animated-button
      text="Zapisz"
      [disabled]="!passwordForm.valid"
      (clicked)="verify()"
      class="full-width"
    />
    <pi-button
      theme="outline"
      text="Anuluj"
      (clicked)="close()"
      class="full-width"
    />
  </div>
</div>
